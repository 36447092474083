const config = {
  global: {
    logOut: '退出',
    changePassword: '修改密码'
  },
  workbench: {
    khxx: '客户信息',
    xptj: '新品推荐',
    yx:"邮箱",
    sc:"市场",
    dz:"地址",
    ndmb:"年度目标",
    ywcxse:"已完成销售额",
    mbwczb:"目标完成占比",
    zsddje:"在手订单金额",
    dhkje:"待回款金额",
    tp:"图片",
    bcxh:"本厂型号",
    zxgg:"装箱规格",
    mj:"面价",
    cpxq:"产品详情",
    fydje:"发运单金额",
    yhkje:"已回款金额",
    wskje:"未收款金额",
    hkr:"回款日",
  },
  menu: {
    gzt: '工作台',
    dccp: '东成产品',
    dccpLpj: '零配件',
    dccpZj: '整机',
    dccpZdz: '转定子',
    dccpFj: '附件',
    dccpSdgj: '手动工具',
    ddzx: '订单中心',
    ddzxygd: '预购单',
    ddzxxdjl: '下单记录',
    ddzxdd:'在手订单',
    ddzxfyd: '发运单',
    dccpZjZl: '直流',
    dccpZjJl: '交流',
    dccpQt: '其他',
  },
  detail: {
    cpcs:"产品参数",
    spxq:"商品详情",
    zxgg:"装箱规格",
    cpsl:"产品数量",
    jrgwc:"加入购物车",
    srgmsl:"请输入购买数量",
    qxzgg:"请选择规格",
    xymoq:"当前下单小于最小起订量",
    xdts:"下单提示",
    dqxdbzx:"当前下单数量不整箱",
    qsrxdsl:"请输入下单数量",
    ywpm:"英文品名",
    pp:"品牌",
    zg:"总共",
    xzgg:"选择规格",
    item:"条",
    yes:"是",
    no:"否",
    dy:"电压"
  },
  Order:{
    ddzxygdxq:"预购单详情"
  },
  Xdjl:{
    dh:"单号",
    xdrq:"下单日期",
    sodf:"售达方",
    shdf:"送达方",
    ddje:"订单金额",
    mz:"毛重",
    jz:"净重",
    tj:"体积",
    xdjl:"下单记录",
    bj:"编辑",
    xdjlxq:"下单记录详情",
    xdjlxg:"下单记录修改",
    mdg:"目的港",
    ddmx:"订单明细",
    hjxs:"合计箱数",
    hjmz:"合计毛重",
    hjjz:"合计净重",
    hjtj:"合计体积",
    djbm:"定价编码",
    cpbm:"产品编码",
    cpmc:"产品名称",
    khxh:"客户型号",
    sl:"数量",
    ygdj:"预估单价",
    ygje:"预估金额",
    cz:"操作",
    sfqrsc:"是否确认删除",
    scsp:"删除商品",
    save:"保存",
    cancel:"取消",
    yjzgr:"期望装柜日",
  },
  Ygd:{
    ygdh:"预购单号",
    ddzt:"订单状态",
    dywqr:"待业务确认",
    yqr:"已确认",
    qwjq:"期望交期",
    dhtqr:"待合同确认",
    fyz:"发运中",
    ygdrq:"预购单日期",
    scht:"上传合同",
    ckzxht:"查看最新合同",
    ckzxbcxy:"查看最新补充协议",
    ygdxq:"预购单详情",
    ygdscht:"预购单上传合同",
    gsmc:"公司名称",
    khmc:"客户名称",
    ddrq:"订单日期",
    zyg:"装运港",
    mysy:"贸易术语",
    zytj:"装运条件",
    yhzh:"银行账户",
    fkfs:"付款方式",
    shfs:"收货方式",
    xs:"箱数",
    qrht:"确认合同",
    htmc:"合同名称",
    htlx:"合同类型",
    xzdz:"下载地址",
    xz:"下载",
    tj:"提交",
    ddsl:"订单数量",
    ygdhh:"预购单行号",
    jq:"交期",
    wjwscwc:"文件未上传完成",
  },
  Zsdd:{
    zsddgl:"在手订单管理",
    zsddxq:"在手订单详情",
  },
  Fyd:{
    fydh:"发运单号",
    fyg:"发运港",
    fl:"返利",
    hyf:"海运费",
    zxb:"中信保",
    fyje:"发运金额",
    zgr:"装柜日",
    tdr:"提单日",
    tdh:"提单号",
    dgr:"到港日",
    fydgl:"发运单管理",
    ddh:"订单号",
    fydxq:"发运单详情",
    fydxz:"发运单新增",
    ckfyht:"查看发运合同",
    fydmx:"发运单明细",
    khjc:"客户简称",
    ddwc:"订单完成",
    bz:"币种",
    hthq:"合同回签",
    fph:"发票号"
  },
  Gwc:{
    gwc:"购物车",
    ddbh:"订单编号",
    ckdh:"查看订单",
    shdh:"收货电话",
    qk:"欠款",
    ddbz:"订单备注",
    gwmx:"购物明细",
    dr:"导入",
    spmc:"商品名称",
    dw:"单位",
    moq:"起订量",
    mj:"面价",
    slzj:"数量总计",
    spzj:"商品总价",
    syb:"上一步",
    qd:"确定",
    yxz:"已选择",
    x:"项",
    qrgwc:"确认购物车",
    qrdd:"确认订单",
    tjwc:"提交完成",
    qxzxdcp:"请选择下单产品",
    qxzsdf:"请选择送达方",
    qxzbz:"请选择币种",
    qxzmdg:"请选择目的港",
  },
  Configuration:{
    search:"搜索",
    reset:"重置",
    xd:"下单",
    mbxz:"模板下载",
    ts:"提示",
    cxjg:"查询结果",
    khzt:"客户自提",
    wtfh:"委托发货",
    dsfkd:"第三方快递",
    wlty:"物流托运",
    hydl:"货运代理",
    zzjc:"自主叫车",
    dc:"导出",
    dlcg:"登录成功",
    qxzcp:"请选择产品",
    khxdh:"客户下单号",
    xdsl:"下单数量",
    qcxdl:"请重新登录",
    cpdrsb:"产品导入失败",
    fysl:"发运数量",
    mxmz:"每箱毛重",
    mzjz:"每箱净重",
    mxtj:"每箱体积",
    yuan:"元",
    yfsl:"已发数量",
    wfsl:"未发数量",
    hylddms:"欢迎来到DMS系统",
    yhm:"用户名",
    mm:"密码",
    dl:"登录",
    sq:"收起",
    zk:"展开",
    more:"更多",
    add:"添加",
    sc:"删除",
    bc:"保存",
    qx:"取消",
    bj:"编辑",
    sfscch:"是否要删除此行",
    qsr:"请输入",
    qxz:"请选择",
    xz:"新增",
    mmcd:"新密码长度不能小于6位",
    qrmmyz:"新密码与确认新密码不一致，请重新输入！",
    dlqr:"您还没有登录，请登录后再访问",
    qxzpp:"请选择品牌",
    jsfp:"简式发票",
    xxfp:"详细发票",
    jsxd:"简式箱单",
    xxxd:"详细箱单",
    fyht:"发运合同",
    xsfp:"形式发票",
    xmm:"新密码",
    qrxmm:"确认新密码",
    btopbz:"表头备注",
    bodytbz:"表体备注",
    ggp:"是否包含广告品",
    qckby:"去除空白页",
    dcbz:"导出备注",
    qr:"确认",
    bp:"标配",
    yswwz:"最终以实物为准"
  },
}

export default config
